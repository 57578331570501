import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import v4 from 'uuid/v4'
import { Helmet } from 'react-helmet'

import { sdkvox } from '../config/sdkvox'
import { sarbacane } from '../config/sarbacane'

import ListAnchors from '../components/ListAnchors'
import Breadcrumb from '../components/Breadcrumb'
import Blocks from '../components/Blocks'
import ScrollTop from '../components/ScrollTop'
import SocialShare from '../components/SocialShare'

const ContentPageTemplate = ({data, pageContext, location}) =>{

    useEffect(() => {
		sdkvox()
		sarbacane()
	})

	const {href} = location
	const {images} = pageContext
	const {featured_media, title, metadesc, wordpress_id, wordpress_parent, hierarchy, blocks} = data.wordpressPage

	const img = featured_media && featured_media.wordpress_id && images.find(e => e.wordpress_id === featured_media.wordpress_id)
	const featuredMedia = img && img.localFile && img.localFile.childImageSharp && img.localFile.childImageSharp.sizes
	
	return (
		<div className="page-template content-page-template d-flex flex-column h-100">
			<Helmet>
				<title>{title}</title>
				{metadesc &&
					<meta name="description" content={metadesc} />
				}
			</Helmet>
			<div className="offline-fallback-fix-no-idea-why"></div>
			{featuredMedia && featuredMedia.base64 !== "undefined" &&
				<Img className="d-none d-lg-block" sizes={featuredMedia}/>
			}
			<Breadcrumb title={title} id={wordpress_id} parent={wordpress_parent} hierarchy={hierarchy}/>
			<Container className="position-relative flex-grow-1">
				<Row className="h-100">
					<Col tag="aside" sm="12" lg="4">
						{title &&
							<h1 className="mt-4 d-block d-lg-none" dangerouslySetInnerHTML={{__html: title }}/>
						}
						<div className="anchors-sidebar h-100 pr-5">
							<div className="bg-light h-100">
								<div className="sticky-top pl-3 pr-5 py-5">
									{blocks && blocks.filter(e => e.blockName === 'cgb/block-anchor').length > 0 &&
										<ListAnchors location={href} key={v4()} anchors={blocks.filter(e => e.blockName === 'cgb/block-anchor')} />
									}
									<SocialShare shareUrl={href} subject={title} />
								</div>

							</div>
						</div>
					</Col>
					<Col tag="article" sm="12" lg="8" className="py-md-5 page-content position-relative">

						{title &&
							<h1 className="d-none d-lg-block" dangerouslySetInnerHTML={{__html: title }}/>
						}

						{blocks && blocks.filter(e => e.blockName).map(f =>
							<Blocks key={v4()} {...f} {...pageContext} />
						)}
						<ScrollTop/>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

ContentPageTemplate.propTypes = {
	data: PropTypes.object.isRequired,
	edges: PropTypes.array,
}

export default ContentPageTemplate

export const pageQuery = graphql`
	query($id: String!) {
		wordpressPage(id: { eq: $id }) {
			title
			wordpress_parent
			wordpress_id
			metadesc
			hierarchy {
				post_id
				post_name
				post_title
				post_parent
				post_link
				menu_order
			}
			featured_media{
				wordpress_id
			}
			blocks{
				blockName
				innerHTML
				attrs{
					image_alt
					image_caption
					image_ahref
					image_atarget
					columns_nb_columns
					columns_column_1
					columns_column_2
					description
					title
					slug
					className
 					fileUrl
					contactContent
					collapseContent
					headingContent
					downloadContent
					foregroundContent
					informationContent
					listinfoContent{
						title
						info
						description
					}
					mediaId
					level
					backgroundColor
					linkName
					fileName
					alignment
					imageid
					mediaName
					subtitle
					align
					ordered
					keywords
					items {
						title
						link
						legend
						imageid
						link
						full_url
						slug
						blank
					}
					displayMode
					labelPlacement
					form_url
					defaultConfirmation{
						message
					}
					wordpress_fields{
						type
						wordpress_id
						label
						isRequired
						cssClass
						size
						description
						errorMessage
						placeholder
						defaultValue
						gravityId
						checkboxLabel
						choices{
							text
							value
							isSelected
						}
						inputs{
							label
							name
							fieldId
							falseValue
							trueValue
						}
					}
					button{
						text
					}
				}
			}
		}
	}
`